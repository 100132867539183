'use client';
import { useRef, useEffect } from 'react';

/**
 * Virtualized list loses focus when scrolling or clicking options, causing the component to re-render.
 * To solve this problem, we need to refocus on the selected option after the component is re-rendered.
 */
export function useFocusVirtualListItem(focused) {
  var itemRef = useRef(null);
  useEffect(function () {
    if (!focused) {
      return;
    }
    if (document.activeElement !== itemRef.current) {
      var _itemRef$current;
      // Focus on the selected option.
      (_itemRef$current = itemRef.current) === null || _itemRef$current === void 0 ? void 0 : _itemRef$current.focus();
    }
  }, [focused]);
  return itemRef;
}
export default useFocusVirtualListItem;